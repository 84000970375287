import React from 'react';
import * as THREE from 'three';
import { Button } from '@mui/material';
import gsap from 'gsap';

import { sort } from './algo';
import Step from "./step";
import Container from '../_commons/container';
import Steps from '../_components/Steps';
import GameWrapper from '../../commons/GameWrapper';
import { clearScene } from '../_commons/three';
import { waitSeconds } from '../_commons/helps';

import info from "./info";

class Item extends THREE.Mesh implements Container {
    payload: number;

    constructor(payload: number, material: THREE.Material, radius: number, height: number) {
        const geometry = new THREE.CylinderGeometry(radius, radius, height, 32);
        super(geometry, material)
        this.payload = payload;
    }
}

const initialColor = "gold";
const enabledColor = "lightgreen";
const finishedColor = "pink";
const indicatorColor = "lightgrey";

const createIndicator = () => {
    const geometry = new THREE.SphereGeometry(1, 12, 12);
    const material = new THREE.MeshBasicMaterial({ color: indicatorColor });
    const sphere = new THREE.Mesh(geometry, material);

    sphere.position.setX(calculateX(0));
    sphere.position.setY(-3.3);
    sphere.position.setZ(-6);
    return sphere;
};

const calculateX = (i: number): number => {
    return i - 8 + 2 * i;
}

const createItems = (values: number[]): Item[] => {
    const items: Item[] = [];

    for (let i = 0; i < values.length; i++) {
        const material = new THREE.MeshBasicMaterial({ color: initialColor });
        let height = values[i];
        const item = new Item(height, material, 1, height);
        item.position.setX(i - 8 + 2 * i);
        item.position.setY(height / 2 - 2);
        item.position.setZ(-6);
        items.push(item);
    }

    return items;
}

interface Props {
    renderer: THREE.Renderer;
    camera: THREE.Camera;
    scene: THREE.Scene;
    values: number[];
}

const changeColor = (c: Container, color: THREE.ColorRepresentation): void => {
    ((c as any).material as THREE.MeshBasicMaterial).color.set(color);
}

const duration = 1;
const ease = "power1";
let animationFrameId = -1;

const indicator = createIndicator();

const Animation = ({ renderer, camera, scene, values }: Props) => {

    const [items, setItems] = React.useState<Item[]>(createItems(values));
    const [index, setIndex] = React.useState<number>(0);
    const [refreshDisabled, setRefreshDisabled] = React.useState(false);
    const [playDisabled, setPlayDisabled] = React.useState(false);

    React.useEffect(() => {
        clearScene(scene);

        items.forEach(item => {
            scene.add(item);
        })
        indicator.position.setX(calculateX(1));
        scene.add(indicator);

        renderer.render(scene, camera);

        return () => cancelAnimationFrame(animationFrameId);
    }, [items, renderer, scene, camera]);

    const run = async (step: Step): Promise<void> => {
        const { min, a, b, exchange } = step;

        indicator.position.setX(calculateX(min));

        if (exchange) {
            if (a === b) {
                changeColor(a, enabledColor);
                setTimeout(
                    () => changeColor(a, finishedColor),
                    duration * 1000
                );
            } else {
                const temp = a.position.clone();
                gsap.to(a.position, {
                    x: b.position.x,
                    duration,
                    ease,
                    onStart: () => changeColor(a, enabledColor),
                    onComplete: () => changeColor(a, initialColor),
                })
                gsap.to(b.position, {
                    x: temp.x,
                    duration,
                    ease,
                    onStart: () => changeColor(b, enabledColor),
                    onComplete: () => changeColor(b, finishedColor),
                });
            }
        } else {
            changeColor(a, enabledColor);
            changeColor(b, enabledColor);
            setTimeout(
                () => {
                    changeColor(a, initialColor);
                    changeColor(b, initialColor);
                },
                duration * 1000
            );
        }

        await waitSeconds(duration);
        return;
    }

    const play = async () => {
        setRefreshDisabled(true);
        setPlayDisabled(true);

        const steps = sort(items);

        animate();
        for (let i = 0; i < steps.length; i++) {
            setIndex(i + 1);
            await run(steps[i]);
        }

        const last = items[items.length - 1];
        changeColor(last, finishedColor);
        scene.remove(indicator);
        await waitSeconds(duration);

        cancelAnimationFrame(animationFrameId);
        setRefreshDisabled(false);
    }

    const refresh = () => {
        cancelAnimationFrame(animationFrameId);
        setIndex(0);
        setItems(() => createItems(values));
        setPlayDisabled(false);
    }

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref && ref.current) {
            const parent = ref.current;
            parent.appendChild(renderer.domElement);
        }

        return () => cancelAnimationFrame(animationFrameId);
    }, [ref, renderer]);

    function animate() {
        animationFrameId = requestAnimationFrame(animate);
        renderer.render(scene, camera);
    };

    return (
        <GameWrapper path={info.path}>
            <>
                <div ref={ref}></div>
                <Steps steps={index} />

                <div style={{ position: "fixed", bottom: "150px", width: "100%", margin: "auto", textAlign: "center" }}>
                    <Button
                        size='large'
                        disabled={playDisabled}
                        onClick={() => {
                            play();
                        }}
                    >
                        play
                    </Button>
                    <Button
                        size='large'
                        disabled={refreshDisabled}
                        onClick={() => {
                            refresh();
                        }}
                    >
                        refresh
                    </Button>
                </div>
            </>
        </GameWrapper>
    );
}

export default Animation;
