export const title = "Plus One";

export const description = `#### Description
You are given a large integer represented as an integer array digits, where each digits[i] is the ith digit of the integer. 

The digits are ordered from most significant to least significant in left-to-right order. 

The large integer does not contain any leading 0's.

Increment the large integer by one and return the resulting array of digits.
`;

export const examples = `

---

#### Example 1:

- Input: digits = [1,2,3]
- Output: [1,2,4]

#### Example 2:

- Input: digits = [9]
- Output: [1,0]
`;
